import Form from '@/utils/form'
import Api from '@/services/api'
import Education from '@/models/Education'
import { each } from 'lodash'
import router from '@/routes'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    educations: { data: [] },
    education: null,
    educationForm: new Form({
      photos: [],
      title: null,
      content: null,
      categories: [],
      is_free: false,
      alternative_educations: [],
      video: [],
      video_from_s3: null,
      thumb_nail_from_s3: null,
    }),
    educationLoading: false,
  },

  getters: {
    contentEducations(state) {
      return state.educations.data.map((obj) => ({
        ...obj,
        education_video_id: obj.education ? obj.education.id : obj.id,
        number_of_sets: null,
        number_of_reps: null,
      }))
    },
  },

  mutations: {
    setEducationCategories(state, payload) {
      state.educationForm.categories = payload
    },
    setEducations(state, payload) {
      state.educations = payload
    },
    setLoadMoreEducations(state, payload) {
      each(payload.data, (education) => {
        state.educations.data.push(new Education(education))
      })
      state.educations.meta = payload.meta
      state.educations.links = payload.links
    },
    pushEducations(state, payload) {
      each(payload.data, (education) => {
        state.educations.data.unshift(new Education(education))
      })
    },
    setSelectedEducation(state, payload) {
      state.selectedEducations.push(payload)
    },
    setEducationErrors(state, payload) {
      if (payload?.thumb_nail) {
        payload['photos'] = payload.thumb_nail
      }
      state.educationForm.$setErrors(payload)
    },
    setEducationLoading(state, payload) {
      state.educationLoading = payload
    },
    clearEducations(state, payload) {
      state.educations = {
        data: [],
      }
    },
    resetEducationFormError(state) {
      state.educationForm.$reset()
    },
    clearFormErrors(state) {
      state.educationForm.$clearErrors()
    },
    setEducationItem(state, payload) {
      state.education = payload
      Object.assign(state.educationForm, payload.data)
      state.educationForm.photos = []
      state.educationForm.photos.push(payload.data.thumb_nail)
      state.educationForm.categories = payload.data.categories
    },
    updateEducation(state, payload) {
      Object.assign(state.educationForm, payload.data)
      state.educationForm.photos = []
      state.educationForm.photos.push(payload.data.thumb_nail)
    },
    updateAltEducation(state, payload) {
      const altEducationIndex =
        state.education.data.alternative_educations.findIndex(
          (altEducation) => altEducation.id === payload.data.id
        )
      Vue.set(
        state.education.data.alternative_educations,
        altEducationIndex,
        payload.data
      )
    },
    removeAltEducation(state, payload) {
      const altEducationId =
        state.education.data.alternative_educations.findIndex(
          (altEducation) => altEducation.id === payload.education_id
        )

      if (altEducationId > -1) {
        state.education.data.alternative_educations.splice(altEducationId, 1)
      }
    },
    setAltEducation(state, payload) {
      state.education.data.alternative_educations.push(payload.data)
    },
  },

  actions: {
    async getEducations(
      { commit },
      params = { page: 1, orderBy: '-created_at' }
    ) {
      commit('setEducationLoading', true)
      const query = Education.page(params.page ? params.page : 1).include([
        'thumb_nail',
      ])
      if (params.search) {
        query.where('search', params.search)
      }

      if (params.category) {
        params['filter'] = { category: params.category }
      }

      if (params.loadMore) {
        delete params.loadMore
        const res = await query.params(params).get()
        commit('setLoadMoreEducations', res)
      } else {
        const res = await query.params(params).get()
        commit('setEducations', res)
      }

      commit('setEducationLoading', false)
    },
    async getEducation({ commit }, payload = {}) {
      try {
        const { data } = await Api.get(`education/videos/${payload.id}`)
        commit('setEducationItem', data)
      } catch ({ response: { data } }) {}
    },
    async updateEducation({ commit }, params) {
      commit('setEducationLoading', true)
      try {
        const data = await Api.post(
          `education/videos/${params.id}?_method=put`,
          params.data
        )
        commit('updateEducation', data.data)
        commit('clearFormErrors')
        commit(
          'setSnackbar',
          { show: true, text: 'Successfully updated' },
          { root: true }
        )
      } catch ({ response: { data } }) {
        commit('setEducationErrors', data.errors)
      }
      commit('setEducationLoading', false)
    },
    async createEducation({ commit }, params) {
      commit('setEducationLoading', true)
      try {
        const { data } = await Api.post(`education/videos`, params)
        // commit('setCategories', [data.data.data])
        commit('resetEducationFormError')
        commit(
          'setSnackbar',
          { show: true, text: 'Successfully created' },
          { root: true }
        )

        router.push({ name: 'education.item', params: { id: data.data.id } })
      } catch ({ response: { data } }) {
        commit('setEducationErrors', data.errors)
      }
      commit('setEducationLoading', false)
    },
    async uploadEducations({ commit }, params) {
      commit('setEducationLoading', true)
      try {
        const data = await Api.post(`education/bulk-batch-upload`, params)
        commit('pushEducations', data.data)
        commit('resetEducationFormError')
        commit(
          'setSnackbar',
          { show: true, text: 'Successfully created' },
          { root: true }
        )
      } catch ({ response: { data } }) {
        commit(
          'setSnackbar',
          { show: true, text: data.errors.file[0], color: 'error' },
          { root: true }
        )
        commit('setEducationErrors', data.errors)
      }
      commit('setEducationLoading', false)
    },
    async deleteEducation({ commit }, id) {
      commit('setEducationLoading', true)
      try {
        await Api.delete(`education/videos/${id}`)
        commit(
          'setSnackbar',
          { show: true, text: 'Successfully deleted' },
          { root: true }
        )
      } catch (data) {}
      commit('setEducationLoading', false)
    },
    async addAltEducation({ commit }, params) {
      commit('setEducationLoading', true)
      try {
        const data = await Api.post(
          `education/${params.education_id}/alternatives`,
          params.data
        )
        commit('setAltEducation', data.data)
        commit('clearFormErrors')
      } catch ({ response: { data } }) {
        commit('setEducationErrors', data.errors)
      }
      commit('setEducationLoading', false)
    },
    async updateAltEducation({ commit }, params) {
      commit('setEducationLoading', true)
      try {
        const data = await Api.post(
          `education/${params.education_id}/alternatives/${params.alt_education_id}?_method=put`,
          params.data
        )
        commit('updateAltEducation', data.data)
        commit('clearFormErrors')
      } catch ({ response: { data } }) {
        commit('setEducationErrors', data.errors)
      }
      commit('setEducationLoading', false)
    },
    async deleteAltEducation({ commit }, params) {
      commit('setEducationLoading', true)
      try {
        await Api.delete(
          `education/${params.education_id}/alternatives/${params.alt_education_id}`
        )
        commit('removeAltEducation', { education_id: params.alt_education_id })
      } catch (data) {}
      commit('setEducationLoading', false)
    },
    async updateThumbNail({ commit }, params) {
      commit('setEducationLoading', true)
      try {
        await Api.post(
          `education/videos/${params.education_id}/update-thumbnail`,
          params.data
        )
        location.reload()
      } catch (data) {}
      commit('setEducationLoading', false)
    },
    async updateVideo({ commit }, params) {
      commit('setEducationLoading', true)
      try {
        await Api.post(
          `education/videos/${params.education_id}/update-video`,
          params.data
        )
        location.reload()
      } catch (data) {}
      commit('setEducationLoading', false)
    },
  },
}
